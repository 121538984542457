@import '/src/assets/styles/mixin.scss';


.rightSideDetailComponent {
  min-height: calc(100vh - 80px);
  width: 50vw;
  background-color: $background-color-primary;
  display: flex;
  justify-content: center;
  position: relative;

  .signupBackButton {
    position: absolute;
    font-size: 40px;
    margin: 20px;
    top: 0;
    left: 0;
    cursor: pointer;

    img {
      height: 45px;
      width: 25px;
    }
  }

  .loginContainer {
    min-width: 40%;
    margin: 20px;
    margin-top: 15vh;


    .pageTitle {
      color: #4a4a4a;
      font-size: 18px;
      margin-bottom: 8px;
    }

    .loginInputContainer {
      margin-top: 24px;

      .loginInputTitle {
        color: #4a4a4a;
        font-size: 18px;
        margin-bottom: 8px;
      }

      .loginInput {
        svg {
          fill: $font-color-primary;
          margin-right: 10px;
        }
      }
    }

    .forgetPassContainer {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;

      .alreadyHaveAccountText {
        opacity: 0.38;
        font-size: 12px;
        margin-right: 10px;
      }

      .signUnLinkButton {
        color: $font-color-primary;
        font-size: 13px;
        cursor: pointer;
        text-decoration: none;
      }
    }

    .startAccountSetupButton {
      margin-top: 10px;
      height: auto;
      width: 308px;

      .startAccountSetupButtonText {
        color: #4a4a4a;
        font-size: 20px;
        font-weight: 700;
        padding: 6px 10px;
      }
    }

    .alreadyHaveAccountContainer {
      margin-top: 16px;
      display: flex;
      justify-content: center;

      .alreadyHaveAccountText {
        color: #000000;
        opacity: 0.38;
        margin-right: 10px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 23px;
      }

      .signUpLinkButton {
        color: $font-color-primary;
        cursor: pointer;
        text-decoration: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 23px;
        text-decoration-line: underline;
      }
    }
  }
}
