.accountSetupMenu {
  margin-top: 20px;

  .accountSetupMenuContainer {
    margin: 24px;

    .accountSetupMenuItem {
      position: relative;

      .accountSetupMenuDot {
        height: 22px;
        width: 22px;
        border-radius: 50%;
        background-color: #6a6a6a;
      }

      .accountSetupMenuTitle {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        color: #ffffff;
        padding-top: 3px;
        opacity: 0.5;
      }

      &.active {
        .accountSetupMenuDot {
          background-color: #4eba9a;
          box-shadow: 0px 0px 6px #4eba9a;
        }

        .accountSetupMenuTitle {
          opacity: 1;
        }
      }

      &.accountSetupMenuItem::after {
        content: "";
        border: 1px solid #6a6a6a;
        height: 8px;
        position: absolute;
        bottom: 0px;
        left: 10px;
      }


      &.accountSetupMenuItem::before {
        content: "";
        border: 1px solid #6a6a6a;
        height: 8px;
        position: absolute;
        top: 0px;
        left: 10px;
      }

      &.accountSetupMenuItem:first-child::before {
        display: none;
      }

      &.accountSetupMenuItem:last-child::after {
        display: none;
      }

      &.active::after {
        content: "";
        border: 1px solid #4eba9a;
        height: 8px;
        position: absolute;
        bottom: 0px;
        left: 10px;
      }


      &.active::before {
        content: "";
        border: 1px solid #4eba9a;
        height: 8px;
        position: absolute;
        top: 0px;
        left: 10px;
      }

      &.active:first-child::before {
        display: none;
      }

      &.active:last-child::after {
        display: none;
      }
    }
  }
}
