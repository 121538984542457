@import '/src/assets/styles/mixin.scss';

.rightSideDetailComponent {
  min-height: calc(100vh - 80px);
  width: 45vw;
  background-color: #F8F2E1;
  display: flex;
  justify-content: center;
  //align-items: center;

  .loginContainer {
    min-width: 65%;
    margin-top: 6vh;

    .title {
      padding-left: 8%;
      font-weight: 600;
      font-size: 30px;
      color: #4A4A4A;
      line-height: 30px;
    }
  
    .titleSecondary {
      padding-left: 8%;
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      color: $font-color-secondary;
      opacity: 35%;
    }
    
    .loginInputContainer {
      padding-left: 10%;
      padding-right: 2%;
      margin-top: 4px;
      .loginInputTitle {
        color: #4a4a4a;
        font-size: 18px;
        margin-bottom: 3px;
      }

      .loginInput {
        svg {
          fill: $font-color-primary;
          margin-right: 10px;
        }
      }
    }

    .goalsButton {
      margin-top: 2vh;
      margin-left: 5%;
      height: fit-content;

      .goalsButtonText {
        color: #707070;
        font-size: 18px;
        padding: 0 5vw;
        text-align: left;
      }
    }

    .saveChangesButton {
      margin-top: 1vh;
      height: auto;

      .saveChangesButtonText {
        color: #707070;
        font-size: 20px;
        font-weight: 700;
        padding: 2px 5px;
      }
    }
  }
}
