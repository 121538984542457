@import "/src/assets/styles/mixin.scss";

.accountSetupMainTitleContainer {

  @include media-breakpoint-up-lg {
    margin: 80px 62px;
  }

  .title {
    font-size: 22px;
    color: #4A4A4A;
    font-family: workSans-medium;
    margin-bottom: 15px;
    font-weight: 100;
  }
}

.accountSetupMainFormContainer {

  .subtitle {
    color: #4A4A4A;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: workSans-medium;
  }

  .inputContainer {
    svg {
      fill: #4EBA9A;
      margin-right: 10px;
      margin-top: 7px;
      margin-bottom: 7px;
    }
    background-color: white;
    border-radius: 8px;
  }

  .instructionText{
    margin-left: 7%;
    opacity: 80%;
    font-size: 14px;
    font-family: workSans-medium;
    display: inline-block;
  }

  .linkText {
    color: #4EBA9A;
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    display: inline-block;
  }

  
  .checkBoxContainer {
    margin-left: 8%;
    margin-top: 12px;
    zoom: 1.5;
    display: inline-block;
  }

  .checkBoxText {
    position: absolute;
    margin-top: 24px;
    margin-left: 20px;
    font-size: 16px;
    font-family: workSans-medium;
    display: inline-block;
  }
  
  .buttonText {
    color: #4A4A4A;
    font-size: 25px;
    font-family: workSans-medium;
    font-weight: 500;
  }

}
