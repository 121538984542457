@import "/src/assets/styles/mixin.scss";

.leftSideDetailComponent {
  height: 100%;
  min-height: calc(100vh - 80px);
  width: 50vw;
  background-color: $background-color-primary;
  border-right: 1px solid #60707070;

  .sliderDots {
    li {
      height: 14px;
      width: 63px !important;

      button {
        height: inherit;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #4EBA9A;
        border-radius: 12px;
        opacity: 1;
      }

    }
  }

  .sliderCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 700px;
    margin: 0 auto;

    .sliderCardImage {
      height: 550px;
      margin-top: 20px;
    }

    .sliderCardMainTitle {
      text-align: center;
      font: normal normal normal 32px/32px WorkSans-Medium;
      letter-spacing: 0px;
      color: #4A4A4A;
    }

    .sliderCardDesc {
      text-align: center;
      font: normal normal normal 18px/25px WorkSans-Medium;
      letter-spacing: 0px;
      color: #4A4A4A;
      margin-top: 22px;
      margin-bottom: 80px;
    }
  }

}
