html {
    margin: 0;
    padding: 0;
    max-height: 100vh;
    overflow: hidden;
}

.f-video-meeting {
    height: 100vh;
    width: 100%;
    display: flex;

    .f-video-meeting-left-sidebar {
        height: 100%;
        background-color: #585858;
        border-right: 1px solid gray;

        .f-video-meeting-left-sidebar-opened {
            height: 100%;
            width: 300px;

            .f-video-meeting-left-sidebar-logo {
                width: 100px;
                padding: 10px 5px;
                padding-bottom: 20px;
                margin: auto;

                img {
                    height: auto;
                    width: 100%;
                }
            }

            .f-video-meeting-left-sidebar-agenda {
                cursor: pointer;
                padding: 10px 5px;
                border-top: 1px solid gray;
                color: white;
                font-weight: bold;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                &:hover {
                    opacity: 0.8;
                }

                .f-video-meeting-left-sidebar-agenda-title {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    div {
                        width: 50%;
                        text-align: end;
                    }
                }
            }
        }

        .f-video-meeting-left-sidebar-closed {
            height: 100%;
            width: 100px;

            .f-video-meeting-left-sidebar-logo {
                width: 100px;
                padding: 10px 5px;
                padding-bottom: 20px;

                img {
                    height: auto;
                    width: 100%;
                }
            }

            .f-video-meeting-left-sidebar-agenda {
                cursor: pointer;
                padding: 10px 5px;
                border-top: 1px solid gray;
                border-bottom: 1px solid gray;
                color: white;
                text-align: center;

                &:hover {
                    opacity: 0.8;
                }

            }
        }

    }

    .f-video-meeting-main-container {
        background-color: black;
        height: 100%;
        width: 100%;
    }

}
