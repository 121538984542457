.step2MainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: pink;
    height: 100%;
    padding: 20px;
    margin-bottom: 50px;

    .titleText {
        color: white;
        font-size: 16px;
        font-weight: 700;
    }

    .titleRoomText {
        font-size: 18px;
        font-weight: 700;
    }

    .flagSvgCotnainer {
        margin: 50px;

        .flagSvg {
            height: 45px;
        }
    }

    .descText {
        font-size: 16px;
        color: white;
    }
}
