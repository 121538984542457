@import "/src/assets/styles/mixin.scss";

.accountSetupMainFormContainer {
  width: 385px;
  height: calc(100% - 275px);
  margin: 0 auto;

  &.accountSetupMainWideFrom {
    width: 450px;
  }

  .formTypeText {
    color: #4eba9a;
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 9px;
  }

  .formQuestionText {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
    margin-top: 30px;
    font: normal normal normal 18px/21px WorkSans-Medium;
    letter-spacing: 0px;
    color: #4A4A4A;
  }

  .formQuestionDetail {
    font-family: futura;
    opacity: 0.62;
    font-size: 14px;
    line-height: 17px;
  }

  .formInputContainer {
    margin-top: 16px;
    height: 460px;

    &.formCheckboxInput {

      .formCheckboxTitle {
        color: #4a4a4a;
        padding-left: 12px;
        line-height: 33px;
        font-weight: 600;
        font-size: 14px;
      }

      .formCheckbox {
        line-height: 33px;
      }
    }

    &.formTextInput {
      .companyIndustryInputBox {
        height: 40px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;

        &:hover,
        &:focus {
          border-color: #4eba9a;
        }
      }

      .companyMailingAddressInputBox {
        font-size: 14px;
        line-height: 16px;
        // font-weight: 500;
        width: 100%;
        height: 40px;
        border: 2px solid #4eba9a;
        border-radius: 9px;
        margin-top: 60px;

        &:hover,
        &:focus {
          border-color: #4eba9a;
        }
      }

      .companyMailingAddressLineContainer {
        display: flex;

        .descriptionCountry {
          margin-top: 10px;
          margin-right: auto;
        }

        .descriptionStreet {
          margin-top: 20px;
          margin-right: auto;
        }
      }

      .companyNameInputBox {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        width: 100%;
        height: 40px;
        border: 2px solid #4eba9a;
        border-radius: 9px;
        padding: 12px;

        &:hover,
        &:focus {
          border-color: #4eba9a;
        }
      }
    }

    &.formCompleteCardContainer {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 40px;

      .formCompleteInfoCardContainer {
        height: 273px;
        margin: 12px;
        padding: 23px 17px 46px 27px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #00000029;
        border-radius: 10px;
        width: 211px;
        min-width: 161px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up-md {
          min-width: 211px;
        }

        .formCompleteInfoCardNumber {
          font-size: 18px;
          min-height: 40px;
          min-width: 40px;
          background: #4eba9a 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 10px #4eba9a;
          color: white;
          border-radius: 80px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include media-breakpoint-up-md {
            font-size: 25px;
            min-height: 60px;
            min-width: 60px;
          }
        }

        .formCompleteInfoCardTitle {
          margin-top: 20px;
          font-size: 12px;
          color: #000000;
          font-weight: 600;

          @include media-breakpoint-up-md {
            margin-top: 26px;
            font-size: 13px;
          }
        }

        .formCompleteInfoCardDetail {
          margin-top: 8px;
          font-size: 11px;
          font-family: "Roboto", sans-serif;
          color: #585858;
          opacity: 0.62;
          line-height: 16px;
          word-spacing: 0;

          @include media-breakpoint-up-md {
            margin-top: 8px;
            font-size: 12px;
            line-height: 17px;
          }
        }
      }
    }
  }

  .nextSectionButtonContainer {

    .accoutSetupNextSectionButton {
      margin-top: 10px;
      height: auto;
      border-radius: 17px;
      width: 308px;

      &.CompleteExploreFramewrkButton {
        margin-top: 45px;
      }

      .accoutSetupNextSectionButtonText {
        color: #4a4a4a;
        font-size: 25px;
        font-weight: 700;
        padding: 12px 76px;
      }

      .accoutSetupSkipSectionButtonText {
        color: #4a4a4a;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}
