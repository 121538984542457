.post-meeting-survey-page {
    height: 100vh;
    width: 100%;
    display: flex;

    .post-meeting-survey-page-left-side {
        height: 100%;
        width: 75%;
        position: relative;
        top: 0;
        left: 0;

        .post-meeting-survey-page-left-side-bg {
            position: relative;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            opacity: 0.8;
            z-index: 1;
        }

        .post-meeting-survey-page-left-side-logo {
            width: 243px;
            height: 96px;
            position: absolute;
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
        }

        .post-meeting-survey-page-left-side-line {
            position: absolute;
            top: 25%;
            left: 50%;
            transform: translateX(-50%);
            height: 50%;
            border: 1px solid white;
            z-index: 2;
        }

        .post-meeting-survey-page-left-side-quote {
            position: absolute;
            top: 80%;
            left: 50%;
            transform: translateX(-50%);
            color: white;
            z-index: 2;
            font-size: 16px;
        }

        .post-meeting-survey-page-left-side-quote-creator {
            position: absolute;
            top: 85%;
            left: 50%;
            transform: translateX(-100%);
            color: white;
            z-index: 2;
            font-size: 16px;

            hr {
                padding-top: 5px;
                width: 60px;
                border-bottom: 5px solid #CFCF22;
            }
        }
    }

    .post-meeting-survey-page-right-side {
        height: 100%;
        width: 25%;
        background-color: #F8F2E1;

        .post-meeting-survey-page-right-side-form {
            height: 100%;
            width: 100%;
            padding: 65px;

            h4 {
                opacity: 0.35;
                margin-bottom: 10px;
            }

            .titlee {
                margin-bottom: 15px;
            }

            .goal-title {
                margin-top: 10px;
            }

            .inputt {
                font-size: 14px;
                line-height: 16px;
                font-weight: 500;
                width: 100%;
                height: 40px;
                border: 2px solid #4eba9a;
                border-radius: 9px;
                padding: 12px;

                &:hover,
                &:focus {
                    border-color: #4eba9a;
                }
            }

            .postMeetingSurveyButtonContainer {
                width: 100%;
                height: 30%;
                display: flex;
                justify-content: center;
                align-items: center;

                .postMeetingSurvey {
                    height: auto;
                    border-radius: 17px;

                    .postMeetingSurveyText {
                        color: #4a4a4a;
                        font-size: 25px;
                        padding: 6px 36px;
                    }
                }
            }

        }
    }
}
