.bar {
  background-color: #DCD7C8;
  width: 80%;
  height: 3px;
  margin-top: 20px;
  margin-left: 2.5%;
  margin-right: 5%;
  position: absolute;
}

.dot{
  margin-top: 17px;
  margin-right: 8.3%;
  height: 9.5px;
  width: 9.5px;
  background-color: #C0C0C0;
  border-radius: 50%;
  display: inline-block;
}

.greenBar{
  position: absolute;
  top: 262px;
  margin-left: 3.6%;
  background-color: #4EBA9A;
  width: 8.5%;
  height: 4px;
  margin-top: 20px;
}

.greenDot{
  position: absolute;
  top: 276px;
  height: 18px;
  width: 18px;
  left: 9.5%;
  background-color: #4EBA9A;
//   box-shadow: 0px 0px 6px #4EBA9A; specific
  border-radius: 50%;
  display: inline-block;
}

.whiteCheck{
  color: white;
  margin-left: 2px;
  margin-top: 1px;
}
