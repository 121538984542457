@import "/src/assets/styles/mixin.scss";

body { 
  background: $background-color-primary; 
  overflow-y: auto;
} 

.accountSetup {
  display: flex;
  height:auto 100%;
  position: relative;

  .accountSetupMainSection {
    width: 85%;
    margin: 20px auto;
    @include custom-scrollbar;
  }

}
