@import "/src/assets/styles/mixin.scss";

.leftSideDetailComponent {
  height: 100%;
  min-height: calc(100vh - 80px);
  width: 50vw;
  background-color: $background-color-primary;
  border-right: 1px solid #60707070;
  display: flex;
  align-items: center;
  flex-direction: column;

  .logo {
    margin-top: 80px;

    img {
      height: 80px;
    }
  }

  .desc {
    font: normal normal normal 20px WorkSans-Medium;
    letter-spacing: 0px;
    color: #4A4A4A;
    width: 617px;
    margin-top: 40px;
  }

  .instructionsTitle {
    font: normal normal bold 30px WorkSans-Medium;
    letter-spacing: 0px;
    color: #4A4A4A;
    margin-top: 80px;
  }

  .instruction {
    display: flex;
    margin: 15px 0;

    .instructionNumber {
      background-color: #CFCF22;
      color: white;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal bold 40px WorkSans-Medium;
    }

    .instructionDetail {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal bold 26px WorkSans-Medium;
      letter-spacing: 0px;
      color: #4A4A4A;
    }
  }

  .footerText {
    font: normal normal normal 20px WorkSans-Medium;
    letter-spacing: 0px;
    color: #4A4A4A;
    margin-top: 80px;
    width: 671px;
  }
}
