
.f-d-flex {
  display: flex;
}

.f-d-flex-d-column {
  flex-direction: column;
}

.f-justify-center {
  justify-content: center;
}

.f-align-center {
  align-items: center;
}

.f-px-4 {
  padding: 0 4px;
}

.f-py-4 {
  padding: 4px 0;
}

.f-px-8 {
  padding: 0 8px;
}

.f-py-8 {
  padding: 8px 0;
}

.f-px-16 {
  padding: 0 16px;
}

.f-py-16 {
  padding: 16px 0;
}

.f-pt-16 {
  padding-top: 16px;
}

.f-px-24 {
  padding: 0 24px;
}

.f-py-24 {
  padding: 24px 0;
}

.f-pt-24 {
  padding-top: 24px;
}

// notification message styles override
.ant-notification-notice {
  width: 500px;
  padding: 10px;

  .ant-notification-notice-icon {
    font-size: 20px;
    height: 20px;
    width: 20px;

    .anticon {
      color: white;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  .ant-notification-notice-message {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin: auto;
    margin-bottom: unset !important;
    margin-left: 40px;
    margin-right: 5px;
  }

  .ant-notification-notice-close {
    top: 13px;
    color: white;
  }
}

.ant-notification-notice.f-toast-info {
  background-color: var(--primary);
}

.ant-notification-notice.f-toast-error {
  background-color: red;
}

.ant-notification-notice.f-toast-success {
  background-color: green;
}

.ant-notification-notice.f-toast-warning {
  background-color: goldenrod;
}
