.schedule-meeting-page {
    height: 100vh;
    width: 100%;
    background-color: #F8F2E1;
    display: flex;
    justify-content: center;
    align-self: center;

    .schedule-meeting-form {
        height: 500px;
        width: 800px;
        transform: translateY(50px);
        padding: 20px;

        h4 {
            opacity: 0.35;
        }

        h3 {
            margin-top: 20px;
        }

        .meetingScheduleButtonContainer {
            margin-top: 20px;
        }
    }

    .schedule-meeting-page-loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
