@import "/src/assets/styles/mixin.scss";

.fwrAuthHeader {
    height: 80px;
    background-color: $background-color-primary;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #00000029;

    .fwrLogo {
        margin: 15px 30px;

        img {
            height: 50px;
        }
    }
}

.fwrkLogin {
    display: flex;
}
