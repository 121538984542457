@import '/src/assets/styles/mixin.scss';


.rightSideDetailComponent {
  min-height: calc(100vh - 80px);
  width: 50vw;
  background-color: $background-color-primary;
  display: flex;
  justify-content: center;
  position: relative;

  .backButton {
    position: absolute;
    font-size: 40px;
    margin: 20px;
    top: 0;
    left: 0;
    cursor: pointer;

    img {
      height: 45px;
      width: 25px;
    }
  }

  .accountSetupContainer {
    min-width: 40%;
    margin: 20px;
    margin-top: 15vh;
  }

  .accountSetupMainSectionLoaderContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
