$background-color-primary: #e1f2e9;
$font-color-primary: #4eba9a;
$font-color-secondary: #3d3d3d;
$element-color-primary: #cfcf22;

// Mid devices (desktops, 1200px and up)
@mixin media-breakpoint-up-md {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

// Large devices (desktops, 1450px and up)
@mixin media-breakpoint-up-lg {
  @media screen and (min-width: 1450px) {
    @content;
  }
}

@mixin custom-scrollbar {
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
