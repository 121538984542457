.fwrGenProgressBar {
    .progressPageTitle {
        color: #4a4a4a;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .progressSlider {
        display: flex;

        .progressSliderDotContainer {
            display: flex;
            align-items: center;

            .progressSliderDot {
                height: 7px;
                width: 7px;
                border-radius: 50%;
                background-color: #000000;
                opacity: 0.11;
            }
            
            .progressSliderTrack {
                height: 4px;
                width: 45px;
                background-color: #DCD7C8;
            }

            .progressSliderDotActive {
                height: 13px;
                width: 13px;
                border-radius: 50%;
                background-color: #4EBA9A;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 2px;
                padding-left: 1px;
            }

            .progressSliderTrackActive {
                height: 4px;
                width: 39px;
                background-color: #4EBA9A;
            }
        }
    }
}
