.titlebar {
    background-color: #F8F2E1;
    padding: 8px;
}

.userListContainer {
    margin-top: 8px;
    border-bottom: 1px solid #d1cec5;
    display: flex;


    .userListLeftSide {
        width: 80px;
        display: flex;

        .greenDot {
            margin: auto 4px;
            height: 8px;
            width: 8px;
            border-radius: 50px;
            background-color: #d8d34f;
        }

        .userImage {
            height: 45px;
            width: 45px;
        }
    }

    .userListRightSide {
        width: 100%;

        h4 {
            margin-top: 5px;
            margin-bottom: 0;
        }
    }
}
