.f-account-page-container {
    background-color: #E1F2E9;
    height: 100vh;
    width: 100%;
    display: flex;

    .f-account-page-plans-main-container {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-family: WorkSans-Medium;

        .f-account-page-plans-page-title {
            font-size: 55px;
            font-weight: 700;
            line-height: 88px;
            letter-spacing: 0em;
            text-align: left;
            border-left: 24px solid #4EBA9A;
            padding: 5px 5px 5px 10px;
            margin-top: 60px;
        }

        .f-account-page-plans-page-desc {
            color: #1C2322;
            font-family: WorkSans-Medium;
            text-align: center;
            font-size: 32px;
            font-weight: 500;
            line-height: 51px;
            margin-top: 21px;
        }

        .f-account-page-plans-container {
            margin-top: 36px;
            display: flex;
            gap: 94px;

            .f-account-page-plans-card {
                display: flex;
                width: 295px;
                padding: 38px 33px 22px 33px;
                flex-direction: column;
                align-items: flex-start;
                gap: 12px;
                border-radius: 12px;
                position: relative;

                &.f-color-orange {
                    background: var(--brand-colors-orange, #F2AF33);
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                }

                &.f-color-yellow {
                    background: #E5E52D;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                }

                &.f-color-cyan {
                    background: #3BC39B;
                    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                }

                .f-plan-type {
                    border-radius: 24px;
                    background: #3C4B48;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 35px;
                    display: flex;

                    button {
                        margin: 4px;
                        border-radius: 14px;
                        width: 80px;
                        background-color: transparent;
                        color: #F5F5F5;
                        text-align: center;
                        font-size: 10px;
                        font-weight: 700;
                        line-height: 26px;
                        letter-spacing: 0.8px;

                        &.f-active {
                            background: #1C2322;
                        }
                    }
                }

                .f-plan-card-title {
                    color: var(--website-titles, #1C2322);
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 22px;

                    .f-plan-card-seat-detail {
                        margin-top: 8px;
                        height: 46px;
                        color: #1C2322;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 22px;
                    }
                }


                .f-plan-card-price-container {
                    display: flex;
                    align-items: center;

                    .f-plan-card-dollar {
                        color: var(--website-titles, #1C2322);
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 24px;
                        padding-top: 5px;
                    }

                    .f-plan-card-price {
                        color: var(--website-titles, #1C2322);
                        font-size: 42px;
                        font-weight: 600;
                        line-height: 62px;
                        letter-spacing: -0.1px;
                    }

                    .f-plan-card-pm {
                        color: var(--website-titles, #1C2322);
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 26px;
                        padding-top: 5px;
                    }
                }

                .f-plan-card-feature {
                    display: flex;

                    .f-plan-card-feature-title-checked {
                        padding-left: 11px;
                        color: var(--website-titles, #1C2322);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0.14px;
                    }

                    .f-plan-card-feature-title-unchecked {
                        padding-left: 11px;
                        opacity: 0.5;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0.14px;
                        text-decoration: line-through;
                    }
                }

                .f-plan-card-choose-button {
                    border-radius: 28px;
                    border: 2px solid #1C2322;
                    background-color: transparent;
                    width: 100%;
                    height: 39px;
                    color: #1C2322;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 19px;
                    letter-spacing: 0.32px;
                    text-transform: capitalize;
                    margin-top: 32px;
                }

            }
        }
    }
}
